export const portalData = {
    // Fax Details
    hasFax: false,
    faxNo: "",
    
    // Whatsapp details
    hasWhatsApp: true,
    whatsAppNo: "+6012 772 9880",
    whatsAppNoHolderName: "Trace Lai",
    hasAltWhatsApp: false,
    altWhatsAppNo: "",
    altWhatsAppNoHolderName: "",
    hasAltWhatsApp1: false,
    altWhatsAppNo1: "",
    altWhatsAppNoHolderName1: "",
    hasAltWhatsApp2: false,
    altWhatsAppNo2: "",
    altWhatsAppNoHolderName2: false,
    
    // Phone number details
    hasPhoneNo: true,
    phoneNo: true,
    phoneNo: "09 - 419 7788",
    hasAltPhoneNo: true,
    altPhoneNo: true,
  
  // Telephone number details
  hasTellNo: true,
  tellNo: "012 - 772 9880",
  tellNoHolderName: "Trace Lai",
  hasAltTellNo: true,
  altTellNo: "014 354 1593",
  altTellNoHolderName: "Trace Lai",
  hasAltTellNo2: false,
  altTellNo2: "",
  altTellNoHolderName2: "",
  
  // Portal information details
  portalNameXSpace: "Aman Tioman Beach Resort",
  portalName: "Aman Tioman Beach Resort",
  portalFullName: "Aman Tioman Beach Resort Sdn Bhd",
  portalFullNameUppercase: "AMAN TIOMAN BEACH RESORT SDN BHD",
  portalShortAddress: "Lot 73, Kampung Paya",
  portalAddress: "Lot 73, Kampung Paya, Pulau Tioman, 26800 Pahang Darul Makmur",
  portalAddressFirstPart: "Lot 73, Kampung Paya, Pulau Tioman,",
  portalAddressSecondPart: "26800 Pahang Darul Makmur",
  portalAddressDetails: "Lot 73, Kampung Paya, Pulau Tioman, 26800 Pahang Darul Makmur",
  hasPortalRegNo: false,
  portalRegNo: "",
  
  // Portal Bank Details
  portalBankName: "CIMB Bank Berhad",
  portalBankAddress: "43-1, No.8, Jalan Anggerik Vanilla, Seksyen 31, 40460 Kota Kemuning, Shah Alam, Selangor",
  portalBankAccHolderName: "Aman Tioman Beach Resort Sdn Bhd",
  portalBankAccNo: "8009170346",
  hasPortalBankSwiftCode: true,
  portalBankSwiftCode: "CIBBMYKL",
   
  portalEmail: "sales@amantioman.com",
  portalTermAndCondUrl: process.env.REACT_APP_PORTAL_TERM_AND_COND_URL,
  
  appName: "Summerbay Digital Intelligence Platform (SBDIP)",

  // GST details
  hasGst: false,
  gstNo: true,
   
  // Website
  portalWebsite: process.env.REACT_APP_PORTAL_WEBSITE,
 
  // Travoxis Website
  travoxisWebsite: process.env.REACT_APP_TRAVOXIS_WEBSITE,
 
  // Facebook page link
  facebookMessengerLink: "https://www.facebook.com/amantiomansales",
 
  // portal S3 base url
  s3AwsUrl: "",
 
  adminPortalUrl: process.env.REACT_APP_ADMIN_PORTAL_URL,

  // Portal Jetty names
  jettyOne: "Mersing Jetty",
  jettyTwo: "Jetty Paya",
  jettyThree: "",
  jettyOneToTwoTime: "11:00 AM",
  jettyTwoToOneTime: "09:30 AM",
  portalAdminUrl: "https://sg-admin.amantioman.com"
}