import demoLogo from "./logo/logo.png";
import summerbaylogo from "../assets/img/logo/sbay-app-logo.png"
import { portalData } from "./portalData";

let  logo = demoLogo

let applicationName = "Travoxios Digital Intelligence Platform (TDIP)"

let siteName = portalData.portalWebsite

let FooterName = "Travoxios (1165017-V)"

if(process.env.REACT_APP_PRODUCTION_MODE !== "STAGE"){
    applicationName = portalData.appName
    logo = summerbaylogo
    FooterName = portalData.portalFullName
}

export {
    logo,
    applicationName,
    siteName,
    FooterName
}
